// ========================================================
// Fonts
// ========================================================

// @import "googlefonts";

@include font-face('Tex', #{$ff__path}texgyreadventor-regular-webfont, normal);
@include font-face('Tex', #{$ff__path}texgyreadventor-italic-webfont, normal, italic);
@include font-face('Tex', #{$ff__path}texgyreadventor-bold-webfont, bold);
@include font-face('Tex', #{$ff__path}texgyreadventor-bolditalic-webfont, bold, italic);
