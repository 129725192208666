
.footer {

    @include mq(sm) {
        display: flex;
    }

    @include mq(lg) {
        padding-left: 10rem;
        padding-right: 10rem;
    }

    [class*='footer__col'] {
        flex-shrink: 1;
        flex-grow: 1;
        flex-basis: auto;
        padding-left: $gutter-width/2;
        padding-right: $gutter-width/2;
    }

    [class*='footer__col'] + [class*='footer__col'] {
        margin-top: 2rem;

        @include mq(sm) {
            margin-top: 0;
        }
    }

    .footer__col-1 {
        img {
            max-width: 100%;
        }

        @include mq(sm) {
            max-width: 350px;
        }

        @include mq(md) {
            padding-right: 5rem;
        }
    }

    .footer__col-2 {
        flex-grow: 2;
    }

    .footer__col-3 > * + * {
        margin-top: 1rem;
    }

    ul {
        list-style: none;
        column-count: 2;
    }

    address {
        color: #7f7f7f;
        line-height: 1.2;
        margin-top: 1rem;
        margin-bottom: 1rem;
        font-style: normal;
    }

    .footer__link {
        color: #7f7f7f;
        transition: color 240ms ease-in-out;

        &:hover {
            color: color(brand, tertiary);
        }
    }
}

.footer__nav {
    li {
        line-height: 1;
        & + li {
            margin-top: .5rem;
        }
    }
}

.footer__copyright {
    font-size: 14px;
    color: #7f7f7f;
}
