footer {
	vertical-align: middle;
	line-height: 1.85em;

	.container {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;

		@include mq(md) {
			flex-direction: row;
		}
	}

	.logo,
	.copyright,
	.logo-globalia {
		@include mq(md) {
			width: 33.33%;
		}
	}

	.logo,
	.copyright {
		text-align: center;
	}

	.logo {
		@include mq(md) {
			text-align: left;
		}

		img {
			width: 270px;
			height: 16px;
		}

		display: block;
		position: relative;
		top: -1px;
	}
	.copyright {
		white-space: nowrap;
		font-size: .75em;
		line-height: 1.5;
		margin: 0 .4em .4em;
		text-align: center;

		span {
			display: block;
			&:before {
				display: none;
			}
		}

		@include mq(md) {
			margin-bottom: 0;
			line-height: inherit;

			span {
				display: inline;

				&:before {
					content: "| ";
					position: relative;
					top:-2px;
					display: inline;
				}
			}
		}

	}

	.logo-globalia {
		text-align: center;
		font-size: .75em;

		img {
			width: 100%;
            max-width: 72px;
			margin-left: .25em;
		}

		@include mq(md) {
			text-align: right;
		}
	}
}
