// ==========================================================================
// Cards
// ==========================================================================

@mixin btn($class: '.btn') {

	$card-border-width	: $border__width !default;
	$card-border-color	: $border__color !default;
	$card-border-radius	: $border__radius !default;

	#{$class} {

		font-size: 1em;
	
		display: inline-block;
		max-width: 100%;

		border: $button-border-width solid $button-border-color;
		border-radius:  $button-border-radius;

		padding: $button-padding;
		margin-bottom: $button-margin;
	}
}