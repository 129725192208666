.u-absolute {
    position: absolute !important;
}

.u-top-0 {
    top: 0 !important;
}

.u-right-0 {
    right: 0 !important;
}

.u-bottom-0 {
    bottom: 0 !important;
}

.u-left-0 {
    left: 0 !important;
}