// ==========================================================================
// Cards
// ==========================================================================

@mixin card($class: '.card', $border: true, $padding: $padding, $figure-ratio: '16/9') {

	$card-border-width	: $border__width !default;
	$card-border-color	: $border__color !default;
	$card-border-radius	: $border__radius !default;

	$figure-picture-ratio: $picture__ratio--apply !default;
	$figure-ratio: $picture__ratio !default;

	#{$class} {

		font-size: 1em;
		
		display: inline-block;
		width: 100%;

		padding: $padding;

		@if $border {
			border: $card-border-width solid $card-border-color;
			border-radius:  $card-border-radius;
		}
	}

	#{$class}__header {
		margin: #{-$padding} #{-$padding} #{$padding} ;
		padding: #{$padding/2} #{$padding};
		
		@if $border {
			border-bottom: $card-border-width solid $card-border-color;
		}
	}

	#{$class}__footer {
		margin: #{$padding} #{-$padding} #{-$padding} ;
		padding: #{$padding/2} #{$padding};
		
		@if $border {
			border-top: $card-border-width solid $card-border-color;
		}
	}

	#{$class}__figure {
		margin: #{-$padding} #{-$padding} $padding;
		position: relative;
    	overflow: hidden;
		@if $figure-ratio {
			figure {
				@include keep-ratio($figure-ratio);
			}
		}
	}

}