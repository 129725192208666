// ==========================================================================
// Forms
// ==========================================================================

$form-border-width	: 1px !default;
$form-border-color	: color(greys,light) !default;
$form-border-radius	: 0 !default;

$form-margin		: $margin !default;
$form-padding		: $padding !default;

$form-size			: 50px !default;
$radio-size         : 20px !default;

$text-inputs-list: (
      'input[type="color"]',
      'input[type="date"]',
      'input[type="datetime"]',
      'input[type="datetime-local"]',
      'input[type="email"]',
      'input[type="month"]',
      'input[type="number"]',
      'input[type="password"]',
      'input[type="search"]',
      'input[type="tel"]',
      'input[type="text"]',
      'input[type="time"]',
      'input[type="url"]',
      'input[type="week"]',
      'textarea'
);

fieldset {
	legend {}
}

:focus {
	outline-style: none;
	box-shadow: none;
	border: 0;
}

.form-item {
	position: relative;
	margin-bottom: 2em;
    width: 100%;
    overflow: hidden;

    @include mq(md) {
        width: 50%;
    }

    label,
    input[type="text"],
    input[type="password"],
    input[type="email"],
    input[type="tel"],
    input[type="search"],
    .select,
    textarea,
    .datepicker {
        width: 100%;
    }

	&--wide {
		@extend .form-item;

        @include mq(md) {
            width: 100%;
        }

		textarea  {
			min-height: 10em;
		}
	}

}


// Input
// --------------------------------------------------
// @example html - Usage
//   <div class="form-item--wide">
//     <input type="text" id="" value="" placeholder="Example of Text Input">
//     <label for="">Name</label>
//   </div>
//
// The value attribute is required

@each $input in $text-inputs-list {

	#{$input} {
		@include placeholder {
			color: transparent;
            text-transform: uppercase;
		}
		background: $snow-white;
		border: $form-border-width solid $form-border-color;
		border-radius:  $form-border-radius;
		padding: .3125em $form-padding;
		height: $form-size;
		line-height: $form-size;

        &:focus {
            border: $form-border-width solid $form-border-color;
        }
	}

	#{$input} + label {
		position: absolute;
		left:0;
		top: .9em;
		padding-left: 1rem;
		transition: all $transition--fast ease-in-out;
		width: auto;
        text-transform: uppercase;
        cursor: text;
        white-space: nowrap;
	}


	#{$input}:not(.datepicker):focus,
	#{$input}:not([value=""]) {
		padding: 10px 1em 0;
	}

	#{$input}:focus + label,
	#{$input}[value=""]:focus + label,
	#{$input}:not([value=""]) + label {
		top: 2px;
		font-size: .625em;
	}

	#{$input}.parsley-error,
	#{$input}.parsley-error + label {
		padding-left: 2rem;
	}

	@if $input == 'input[type="email"]' {
		#{$input}.parsley-error {
			padding: 10px 1em 0;
		}
		#{$input}.parsley-error + label {
			top: 2px;
			font-size: .625em;
		}
		#{$input}.parsley-error,
		#{$input}.parsley-error + label {
			padding-left: 2rem;
		}
		#{$input}[value=""] + label {
			top: .9em;
			font-size: 1em;
		}
	}
}

.form-picker {
    position: relative;
    @extend .fa-calendar;

    input {
        @include placeholder {
			color: $fc;
		}
    }

    &:before {
        @extend .fa;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 1em;
    }
}

.form-file {
    overflow: hidden;

    .input-file {
        position: relative;
        background: $snow-white;
        border: $form-border-width solid $form-border-color;
        border-radius:  $form-border-radius;
        padding: 0 $form-padding;
        height: $form-size;
        line-height: $form-size;
        text-transform: uppercase;
        cursor: pointer;
        @extend .fa-paperclip;

        &:before {
            @extend .fa;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
        }
    }

    .input-multi {
        position: absolute;
        top: -3000px;
    }

    @include mq(md) {
        .input-file {
            float: left;
            width: 50%;
        }

        .MultiFile-wrap {
            float: left;
            width: 45%;
            margin-left: 5%;
        }
    }
}

input[type="submit"],
button {
	cursor: pointer;
}


// Radio + Checkbox
// --------------------------------------------------
// @example html - Usage
//  <div class="form-item--wide">
//     <div class="radio-container">
//         <div class="radio">
//             <input type="radio" name="" id="">
//             <label for="">Opt IN</label>
//         </div>
//         <div class="radio">
//             <input type="radio" name="" id="">
//             <label for="">Opt Out</label>
//         </div>
//     </div>
// </div>
// <div class="form-item--wide">
//     <div class="checkbox-container">
//         <div class="checkbox">
//             <input type="checkbox" name="" id="">
//             <label for="">Remember me</label>
//         </div>
//     </div>
// </div>

.radio-container,
.checkbox-container {

	@include clearfix;
	margin-top: 0.3125em;
	margin-bottom: 2em;

	&--float {
		@extend .radio-container;
		.radio,
		.checkbox {
			float: left;
		}
	}

	input[type=radio],
	input[type=checkbox] {
		display: none;
	}

	label:before,
	input[type=radio] + label:after,
	input[type=checkbox] + label:after,
	input[type=radio]:checked + label:after {
		width: $radio-size;
		height: $radio-size;
	}

	label:before,
	input[type=radio] + label:after,
	input[type=checkbox] + label:after {
		position: absolute;
		left:0;
		top:0;
	}

    label {
		position: relative;
		display: inline-block;
		padding-left: $radio-size * 1.25;
		line-height: $radio-size;
		min-height: $radio-size;
		cursor: pointer;

		&:before {
			display: inline-block;
			content: "";
			background-color: $white;
			border: $form-border-width solid $form-border-color;
			transition: border-color $transition--fast ease-in-out;
		}
	}

	.radio, .checkbox {
		margin-right: 1.25em;
		label {
			margin-bottom: .625em;
		}
	}

	.checkbox label:before {
		border-radius: 0;
	}
	.radio label:before {
		border-radius: 50%;
	}

	input[type=radio] + label:after {
		font-size: $radio-size / 1.8;
		content: $fa-var-circle;
	}
	input[type=checkbox] + label:after {
		font-size: $radio-size / 1.25;
		content: $fa-var-check;
	}

	input[type=radio] + label:after,
	input[type=checkbox] + label:after {
		@extend .fa;
		display: block;
		text-align: center;
		opacity: 0;
		line-height: $radio-size;
		color: color('brand', 'secondary');
		transition: all $transition--fast ease-in-out;
		transform: scale(0);
	}
	input[type=radio]:checked + label:after,
	input[type=checkbox]:checked + label:after {
		opacity: 1;
		transform: scale(1);
	}

	& + .parsley-errors-list {
		display: none;
	}
	.radio .parsley-error + label:before,
	.checkbox .parsley-error + label:before {
		border: $form-border-width solid #f81943;
	}
}

.radio-container {
    label {
        text-transform: uppercase;
	}
}


// Select
// --------------------------------------------------
// @example html - Usage
//   <select class="js-custom-select">
//     <option value="hide">All platforms</option>
//     <option value="mobile-game">Mobile game</option>
//		<option value="playstation">Playstation</option>
//     <option value="xbox">Xbox</option>
//   </select>

.select {
	position: relative;
	display: inline-block;
	//width: 100%;
	height: $form-size;
	cursor: pointer;
	background-color: $white;

	.select-styled,
	.select-options {
		border: $form-border-width solid $form-border-color;
		border-radius: $form-border-radius;

	}

	select:focus ~ .select-styled,
	select:focus ~ .select-options {
		border-color: $color--primary;
	}

	.select-hidden {
		opacity:0;
	}

	.select-styled {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		line-height: $form-size;
		text-align: left;
		padding: 0 $form-padding;


		&:after {
			@extend .fa;
			font-size: 1.5em;
			content: $fa-var-angle-down;
			position: absolute;
			top: 0;
			right: 18px;
			line-height: $form-size;
		}

		&:focus,
		&:active,
		&.is-active {
			border-bottom-color: $black;
			&:after {
				content: $fa-var-angle-up;
			}
		}
	}

	.select-options {
		display: none;
		position: absolute;
		top: 100%;
		right: 0;
		left: 0;
		z-index: 50;
		text-align: left;
		background: $snow-white;


		li {
			padding:  $form-padding;
			list-style-type: none;
			background: $snow-white;
			transition: all $transition--fast;
			border-top:1px solid #000;

			&:nth-child(2) {
				border-top:0;
			}
			&:hover {
				background: color('brand', 'secondary');
			}

			&[rel="hide"] {
				display: none;
			}

		}
	}
}


input.parsley-error,
input.parsley-error:focus,
label.parsley-error input[type="checkbox"]:after,
label.parsley-error input[type="radio"]:after {
	border: 1px solid $color--error;
}
.parsley-errors-list {
	list-style: none;
	text-align: right;
	color: color('parsley', 'error');
	font-size: .9em;

	&:before {
		position: absolute;
		left: 1em;
		top: 1.07em;
		font-family: $ff__fa;
		content: $fa-var-exclamation-triangle;
		color: color('parsley', 'error');
	}
	&:empty:before {
		display: none;
	}
}


.g-recaptcha {
    margin-bottom: 1rem;
}

.recaptcha-error {
    margin: 1rem 0;
    font-size: 14px;
    color: red;
}


