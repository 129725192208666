.tab {
    &__head {
        border-bottom: 1px solid color(greys,xlight);
        margin-bottom: 2em;
        overflow: hidden;

        & > div {
            padding: 1em 1.5em;
            color: $color--primary;
            cursor: pointer;

            &.is-active {
                color: $snow-white;
                background-color: $color--primary;
            }
        }
    }

    &__content {
        display: none;

        &.is-active {
            display: block;
        }

        .product-link-anchor {
            display: block;
        }

        .product-link {
            max-width: 85px;
            transition: filter .2s ease-out;
            display: block;

            &.threem-link {
                max-height: 30px;
            }

            &:hover {
                filter: grayscale(100%);
            }
        }
    }
}
