// ==========================================================================
// Maps
// ==========================================================================

// Color mapping
// -------------
// @example scss - Usage
//   color: color('greys', 'xlight');
//   color: color('primary', 'dark');
//   color: color('secondary');
//   color: color('brands', 'facebook');
//
// return base color as default

$colors : map-merge(
    (
        brand: (
            primary     : $color--primary,
            secondary   : $color--secondary,
            tertiary    : $color--tertiary,
            quaternary  : $color--quaternary

        ),
        greys : (
            xdark       : #1E1E1E,
            dark        : #575757,
            base        : #808080,
            light       : #bebebe,
            xlight      : #E8E8E8
        ),
        parsley : (
            error       : $color--error
        )
    ), $colors-project
);


// Social Medias icons/color mapping
// -------------

$socials : (
    twitter     : (char: "\f099", color: #55acee),
    google      : (char: "\f0d5", color: #dd4b39),
    facebook    : (char: "\f09a", color: #3b5998),
    pinterest   : (char: "\f09a", color: #bd081c),
    instagram   : (char: "\f09a", color: #3f729b),
    linkedin    : (char: "\f0e1", color: #0077b5),
    rss         : (char: "\f09e", color: #ff6600)
);


// Custom easing mapping
// -------------
$easing : (
    easeinsine      : cubic-bezier(0.47, 0, 0.745, 0.715),
    easeoutsine     : cubic-bezier(0.39, 0.575, 0.565, 1),
    easeinoutsine   : cubic-bezier(0.39, 0.575, 0.565, 1),

    easeinquad      : cubic-bezier(0.55, 0.085, 0.68, 0.53),
    easeoutquad     : cubic-bezier(0.25, 0.46, 0.45, 0.94),
    easeinoutquad   : cubic-bezier(0.25, 0.46, 0.45, 0.94),

    easeincubic     : cubic-bezier(0.55, 0.055, 0.675, 0.19),
    easeoutcubic    : cubic-bezier(0.215, 0.61, 0.355, 1),
    easeinoutcubic  : cubic-bezier(0.215, 0.61, 0.355, 1),

    easeinquart     : cubic-bezier(0.895, 0.03, 0.685, 0.22),
    easeoutquart    : cubic-bezier(0.165, 0.84, 0.44, 1),
    easeinoutquart  : cubic-bezier(0.165, 0.84, 0.44, 1),

    easeinquint     : cubic-bezier(0.755, 0.05, 0.855, 0.06),
    easeoutquint    : cubic-bezier(0.23, 1, 0.32, 1),
    easeinoutquint  : cubic-bezier(0.23, 1, 0.32, 1),

    easeinexpo      : cubic-bezier(0.95, 0.05, 0.795, 0.035),
    easeoutexpo     : cubic-bezier(0.19, 1, 0.22, 1),
    easeinoutexpo   : cubic-bezier(0.19, 1, 0.22, 1),

    easeincirc      : cubic-bezier(0.6, 0.04, 0.98, 0.335),
    easeoutcirc     : cubic-bezier(0.075, 0.82, 0.165, 1),
    easeinoutcirc   : cubic-bezier(0.075, 0.82, 0.165, 1),

    easeinback      : cubic-bezier(0.6, -0.28, 0.735, 0.045),
    easeoutback     : cubic-bezier(0.175, 0.885, 0.32, 1.275),
    easeinoutback   : cubic-bezier(0.68, -0.55, 0.265, 1.55)
);
