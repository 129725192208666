
.banner-small {
    @include mq(sm) {
        display: flex;
        justify-content: center;
        align-items: center;

        > * {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: 50%;
            min-width: 50%;
        }
    }

    .banner-small__body {
        > div {
            @include mq(sm) {
                max-width: 500px;
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    img {
        max-width: 100%;
    }
}
