// ==========================================================================
// Global
// ==========================================================================

html {
	font-size: $fs;
		-ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

body {
	font-family: $ff;
	font-size: $fs;
	color: $fc;
	line-height: $lh;
}

hr {
	border-top: 1px solid $border__color;
	margin: $margin 0;
}

.h-text-color-primary {
    color: $color--primary;
}
