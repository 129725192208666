
.u-color-white {
    color: white !important;
}

.u-color-tertiary {
    color: color(brand, tertiary);
}

.u-color-quaternary {
    color: color(brand, quaternary);
}

.u-background-gradient {
    background: linear-gradient(141.59deg, rgba(22, 106, 136, 0.75) 28.07%, rgba(45, 193, 122, 0.75) 100%), #166A88;
}

.u-background-grey-light {
    background-color: #F2F2F2;
}

.u-background-primary {
    background-color: color(brand, tertiary);
}

.u-background-secondary {
    background-color: color(brand, quaternary);
}
