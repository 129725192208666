.accordeon {
    color: color(brand, primary);
    counter-reset: accordeon;

    .cta & {
        margin-top: -1em;
    }

    &__box {
        margin-bottom: 16px;
        border-radius: 4px;
        transform: translate3d(0,0,0);

        border: 1px solid color(greys, xlight);
    }

    &__head {
        position: relative;
        padding: .75rem .75rem;
        cursor: pointer;

        h1 {

            font-size: 1.25rem;
            line-height: 2.25rem;
            transition: color .2s ease-in;
            font-weight: 300;

            &:before {

                counter-increment: accordeon;
                content: counters(accordeon, "",  decimal-leading-zero);
                display: inline-block;
                font-weight: 700;
                font-size: 2.25rem;
                line-height: 1;
                padding-right: .3em;
                margin-right: .4em;
                border-right: 1px solid color(greys, xlight);;
            }


            &:hover {
            }
        }


        &:after {
            @extend .fa;
            content:"\f0d7";
            position: absolute;
            top: 50%;
            right: .75rem;
            width: 1em;
            height: 1em;
            margin-top: -.5em;
            font-weight: 900;
            text-align: center;
            line-height: 1em;

        }

        &.is-toggled:after {
            content: '\f0d8';
        }
    }

    &__content {
        height: 0;
        overflow: hidden;
        transition: .2s ease-in;
        color: $fc;

        p {
            font-size: 1rem;
            padding: 1rem .75rem;

            &:last-of-type {
                padding-bottom: 1rem;
            }
        }

        .btn--primary {
            margin-top: 0;
            margin-left: .75rem;
            margin-bottom: 2rem;
        }
    }
}

.accordeon_linked {
    figure {
        display: none;
        &:first-child {
            display:block;
        }
    }
}
