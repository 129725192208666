.form--contact {
	padding: 2em .5em;
	overflow: hidden;
	background-color: color(greys,xlight);

	&__col {
		padding: 0 1em;
	}

	textarea {
		min-height: 13.4em;
	}

	.btn--primary {
		min-height: 50px;
		margin-top: 0;
		width: 100%;
		background-color: #2DC17A;
	}

	&.is-sent {
		#contact {
			display: none;
		}

		.form--success {
			display: block;
		}
	}

	@include mq(md) {
		&__col {
			float: left;
			width: 50%;
		}
	}
}

.form--referring {
	.form--success {
		background-color: $white;
	}

	&.is-sent {
		#referring {
			display: none;
		}

		.form--success {
			display: block;
		}
	}
}

.form--success {
	display: none;
	text-align: center;

	&:before {
	    content: '';
	    display: table;
		clear: both;
	}

	&__msg {
		position: relative;
		margin: 8.5em 0 3em;
		@extend .fa-smile-o;

		&:before,
		&:after {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
		}

		&:before {
			top: -2.3em;
			@extend .fa;
			font-size: 2em;
			color: $color--success;
		}

		&:after {
			content: '';
			top: -5.9375em;
			width: 4.6875em;
			height: 4.6875em;
			border: 2px solid $color--success;
			border-radius: 100%;
		}
	}

	.btn--primary {
		width: auto;
	}
}
