// ==========================================================================
// Grid Custom System
// ==========================================================================

/// Requires map-variables $breakpoints
///
/// @example scss - Usage
///     @include grid(wrapper-class, columns, gutter-width, max-width, flexbox-enabled?);
///     params:  wrapper-class (ex:'.grid')
///              columns (ex:12)
///              gutter-width (ex:10)
///              max-width (ex:100%)
///              flexbox-enabled (ex:false)
///
/// @example css - CSS Output (flexbox-enabled=false)
///    .grid {
///       max-width : 100%;
///       (...)
///    }
///    .row  {
///       margin-left : -5px;
///       margin-right : -5px;
///       (...)
///    }
///     .col-(breakpoint)-(1-12) {
///       float: left;
///       width : 100%/(1-12);
///       padding: 0 5px 10px;
///       (...)
///    }

%row-block {
    position: relative;
    display: flex;
    flex-flow: row wrap;

    @if ($gutter-width != 0) {
        margin-left: -$gutter-width/2;
        margin-right: -$gutter-width/2;
    }
}

%block {
    flex: 1 1 auto;
    flex-basis: 100%;
    max-width: 100%;

    @if ($gutter-width != 0) {
        padding-left: $gutter-width/2;
        padding-right: $gutter-width/2;
        margin-top: $gutter-width;
    }
}


@mixin grid($class: '.grid', $grid-columns : 12, $gutter : 0, $max-width : false, $flexbox-enabled : false){
    $attribute: "gr-grid";


    // Row Block-Grid
    [#{$attribute}~=row--block] {
        @extend %row-block;
    }

    // Block column
    [#{$attribute}=block] {
        @extend %block;
    }

    #{$class} {
        position: relative;
        width: 100%;
        @if($max-width == false){
            $max-width : calc(100% - #{$gutter});
        }
        max-width: $max-width;
        margin-left: auto;
        margin-right: auto;
        //overflow: hidden;
        @if($gutter != 0){
            padding-left: $gutter/2;
            padding-right: $gutter/2;
        }
        // Rows
        .row {
            display: block;
            margin-left:-$gutter/2;
            margin-right:-$gutter/2;
            position: relative;

            &:after, &:before {
              content: "";
              display: table;
              clear: both;
            }
            //overflow: hidden;

            @if $flexbox-enabled {
                :root.webkitbox &,
                :root.flexbox & {
                    display: flex;
                    flex: 0 1 auto;
                    flex-direction: row;
                    flex-wrap: wrap;

                    &.reverse {
                        flex-direction: row-reverse;
                    }

                    .col {
                      flex: 1 1 0;
                    }
                }
            }
            // Columns
            & > * {
                display: block;
                width: 100%;
                margin: 0;
                @if($gutter != 0){
                    padding-left: $gutter/2;
                    padding-right: $gutter/2;
                }
                @if $flexbox-enabled {
                    :root.webkitbox &,
                    :root.flexbox & {
                        &.reverse {
                            flex-direction: column-reverse;
                        }
                    }
                }
            }
        }
        @each $bp, $w in $breakpoints {
            @media only screen and (min-width: #{$w}) {
                @for $i from 1 through $grid-columns {

                    @if $flexbox-enabled {
                        :root.webkitbox &,
                        :root.flexbox &{
                            .col-#{$bp}-#{$i} {
                                flex: 0 0 auto;
                                flex-basis: 100% / $grid-columns * $i;
                                max-width: 100% / $grid-columns * $i;
                            }
                            .col-#{$bp}-auto {
                                flex-grow: 1;
                                flex-basis: 0;
                                max-width: 100%;
                            }
                            .start-#{$bp} {
                                justify-content: flex-start;
                                text-align: start;
                            }
                            .center-#{$bp} {
                                justify-content: center;
                                text-align: center;
                            }
                            .end-#{$bp} {
                                justify-content: flex-end;
                                text-align: end;
                            }
                            .top-#{$bp} {
                                align-items: flex-start;
                            }
                            .middle-#{$bp} {
                                align-items: center;
                            }
                            .bottom-#{$bp} {
                                align-items: flex-end;
                            }
                            .around-#{$bp} {
                                justify-content: space-around;
                            }
                            .between-#{$bp} {
                                justify-content: space-between;
                            }
                            .first-#{$bp} {
                                order: -1;
                            }
                            .last-#{$bp} {
                                order: 1;
                            }
                        }
                        :root.no-webkitbox &,
                        :root.no-flexbox & {
                            .col-#{$bp}-#{$i} {
                                float: left;
                                width: 100% / $grid-columns * $i;
                            }
                        }
                     } @else {
                        .col-#{$bp}-#{$i} {
                            float: left;
                            width: 100% / $grid-columns * $i;
                        }
                    }
                    .col-#{$bp}-offset-#{$i} {
                        margin-left: 100% / $grid-columns * $i;
                    }
                    .col-#{$bp}-pull-#{$i} {
                        position: relative;
                        right: percentage(($i / $grid-columns));
                    }
                    .col-#{$bp}-push-#{$i} {
                        position: relative;
                        left: percentage(($i / $grid-columns));
                    }


                    [#{$attribute}~=row--block] {
                        &[#{$attribute}~='#{$bp}-#{$i}'] {
                            [#{$attribute}=block] {
                                flex-basis: 100% / $i;
                                max-width: 100% / $i;

                                &:nth-child(n) {
                                    margin-top: 0;
                                }

                                &:nth-child(n + #{$i + 1}) {
                                    margin-top: $gutter;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
