// ==========================================================================
// Cards
// ==========================================================================

$card-border-width	: $border__width !default;
$card-border-color	: $border__color !default;
$card-border-radius	: $border__radius !default;

$card-margin		: $margin !default;
$card-padding		: $padding !default;


.cards {
	list-style: none;
}

.card {

	font-size: 1em;

	display: inline-block;
	width: 100%;

	border: $card-border-width solid $card-border-color;
	border-radius:  $card-border-radius;

	padding: $card-padding;
	margin-bottom: $card-margin;
}

.card__header {
	margin: #{-$card-padding} #{-$card-padding} #{$card-padding} ;
	padding: #{$card-padding/2} #{$card-padding};
	border-bottom: $card-border-width solid $card-border-color;
}

.card__footer {
	margin: #{$card-padding} #{-$card-padding} #{-$card-padding} ;
	padding: #{$card-padding/2} #{$card-padding};
	border-top: $card-border-width solid $card-border-color;
}

.card-small {
    display: inline-block;
    border: 1px solid color('greys', 'xlight');
    width: 100%;

    img {
        max-width: 100%;
        padding-bottom: 10px;
    }

    p {
        padding-left: #{$card-padding/2};
        padding-right: #{$card-padding/2};
        color: $color--primary;
    }
}
