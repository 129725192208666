

.banner-text-image {
    @include mq(sm) {
        display: flex;
        > * {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: auto;
        }
    }

    .banner-text-image__image {
        @include keep-ratio('1/1.5');
       // @include change-ratio('158/140', sm);
       background-size: cover;
        background-position: center;

        @include mq(sm) {
            max-width: 40%;
            @include keep-ratio('158/140');
        }
    }

    .banner-text-image__body {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5rem 2rem;

        @include mq(sm) {
            max-width: 60%;
            padding: 2rem;
        }

        @include mq(lg) {
            > div {
                max-width: 400px;
            }
        }
    }
}

.banner-text-image--invert {
    @include mq(sm) {
        flex-direction: row-reverse;
    }
}
