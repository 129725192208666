// flex
.u-flex {
    display: flex;
}

.u-items-center {
    align-items: center;
}

.u-justify-center {
    justify-content: center;
}
