.showcase {
    @include mq(sm) {
        display: flex;
        padding-left: $gutter-width--small;
        padding-right: $gutter-width--small;

        > div {
            flex-grow: 1;
            flex-shrink: 1;
            flex-basis: auto;
        }
    }

    a {
        display: block;
    }

    > div:nth-child(1) {
        display: flex;
        align-items: center;
    }

    > div:nth-child(1) > * {
        margin: $gutter-width--small/2;
        width: 100%;
        height: calc(100% - #{$gutter-width--small});
    }

    > .showcase__grid {
        display: flex;
        flex-wrap: wrap;

        > div {
            flex-shrink: 1;
            flex-grow: 1;
            flex-basis: 50%;
            min-width: 50%;
            padding: $gutter-width--small/2;

            > a {
                min-height: 90px;
                height: 100%;
                background-size: cover;
                background-position: center;
            }
        }
    }

    .showcase__image-featured {
        @include keep-ratio('181/103');
    }

    .showcase__image {
        position: relative;

        @include mq(md) {
            &:hover .showcase__hover {
                opacity: 1;
            }
        }
    }

    .showcase__hover {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(33.07deg, rgba(22, 106, 136, 0.2) 1.06%, rgba(45, 193, 122, 0.2) 100%), linear-gradient(0deg, rgba(22, 106, 136, 0.8), rgba(22, 106, 136, 0.8)), linear-gradient(0deg, rgba(22, 106, 136, 0.2), rgba(22, 106, 136, 0.2));
        transition: opacity 240ms ease-in-out;
        color: white;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
    }
}
