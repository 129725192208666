.contact-box {
    position: relative;
    min-height: 3.75em;
    margin-bottom: 1.5em;
    padding: 1em 0 1em 4.25em;
    border-radius: .25em;
    border: 1px solid color(greys,light);
    font-size: 14px;

    @include mq(xs) {
        padding-left: 5.25em;
        font-size: 16px;
    }

    .btn--icon {
        position: absolute;
        top: .375em;
        left: .75em;
    }

    a {
        font-weight: normal;
        color: color(brand, primary);
    }

    span {
        display: block;

        @include mq(md) {
            display: inline-block;
            margin-top: 0;

            & + span {
                margin-left: 3.125em;
            }
        }
    }

    &.schedule {
        padding-left: 0;

        .btn--icon {
            display: none;
        }

        @include mq(lg) {
            padding-left: 2.75em;

            .btn--icon {
                display: block;
            }

            .schedule__dayname:nth-child(1) {
                &:before {
                    left: -2.75em;
                    width: calc( 500% + 2.75em + 5px );
                }
            }
        }
    }
}

.schedule {
    @include clearfix();

    &__day {
        margin-bottom: 1em;
    }

    &__dayname,
    &__dayhours {
        padding: .125em 0;
        text-align: center;
    }

    &__dayname {
        text-transform: uppercase;
    }

    &__dayhours {
        font-size: .75em;
        color: color(brand, primary);
    }

    @include mq(md) {
        &__day {
            float: left;
            width: 20%;
            margin-bottom: 0;

            &:first-child {
                .schedule__dayname {
                    position: relative;

                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        bottom: -1em;
                        height: 1px;
                        width: 500%;
                        background-color: color(greys,light);
                    }
                }
            }

            &:not(:last-child) {
                .schedule__dayname,
                .schedule__dayhours {
                    border-right: 1px solid color(greys,light);
                }
            }
        }

        &__dayhours {
            height: 40px;
            margin-top: 2.667em;

            &.closen {
                padding: .8em 0;
            }
        }
    }
}

.contact-box__desc,
.cta .contact-box__desc {
    text-align: center;
    padding: 1em 2em 0;
    display: flex;

    @include mq(md) {
        padding-top: 2em;
    }
}
