.persona {
    justify-content: center;

    .persona__hover {
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: linear-gradient(33.07deg, rgba(22, 106, 136, 0.2) 1.06%, rgba(45, 193, 122, 0.2) 100%), linear-gradient(0deg, rgba(22, 106, 136, 0.8), rgba(22, 106, 136, 0.8)), linear-gradient(0deg, rgba(22, 106, 136, 0.2), rgba(22, 106, 136, 0.2));
        transition: opacity 240ms ease-in-out;
        color: white;
        font-size: 24px;
        text-transform: uppercase;
        font-weight: 600;
        text-align: center;
    }

    @include mq(sm) {
        a:hover {
            .persona__hover {
                opacity: 1;
            }
        }
    }

    .persona__img {
        @include keep-ratio('125/150');
        position: relative;
        background-position: center;
        background-size: cover;
        overflow: hidden;
    }

    @include mq(sm) {
        [gr-grid=block] {
            max-width: 500px !important;
            padding-left: $gutter-width--small/2;
            padding-right: $gutter-width--small/2;
        }
    }
}
