.u-font-large {
    font-size: 36px;
    line-height: 1.2;

    @include mq(sm) {
        font-size: 42px;
    }

    @include mq(md) {
        font-size: 70px;
        line-height: 1;
    }
}

.u-font-36 {
    font-size: 24px;
    line-height: 1.2;

    @include mq(md) {
        font-size: 36px;
    }
}

.u-font-30 {
    font-size: 20px;
    line-height: 1.2;

    @include mq(md) {
        font-size: 30px;
    }
}

.u-font-24 {
    font-size: 16px;
    line-height: 1.2;

    @include mq(md) {
        font-size: 24px;
    }
}

.u-font-weight-light {
    font-weight: 300;
}

.u-font-weight-700 {
    font-weight: 700;
}

.u-text-center {
    text-align: center;

    p {
        text-align: center;
    }
}

.u-uppercase {
    text-transform: uppercase;
}
