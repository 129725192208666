// ==========================================================================
// Variables
// ==========================================================================

// Global
$fs       : 16px;       // Font-size global (apply on htm, body)
$lh       : 1.4;        // Line-height global
$fc       : #504b4b;    // Font color

$globalia : #1982c4;

$border__width  : 1px;
$border__color  : $fc;
$border__radius : 1em;

$margin   : 1em;
$padding  : 1em;

// Font families
$ff                    : "Tex", "Helvetica Neue", Helvetica, sans-serif;
$ff__path              : '../fonts/';
$ff__primary           : $ff;
$ff__primary--bold     : $ff__primary;
$ff__primary--light    : $ff__primary;
$ff__secondary         : "Helvetica Neue", Helvetica, sans-serif;
$ff__secondary--bold   : $ff__secondary;
$ff__secondary--light  : $ff__secondary;
$ff__icon              : "";
$ff__fa                : "fontAwesome";

// Headings
$h1 : $fs * 2.25;   // 36px;
$h2 : $fs * 1.75;   // 28px;
$h3 : $fs * 1.5;    // 24px;
$h4 : $fs * 1.25;   // 20px;
$h5 : $fs * 1.125;  // 18px;
$h6 : $fs;          // 16px;
// Colors
$color--primary     : #006987;
$color--secondary   : #4dc208;
$color--tertiary    : #166A88;
$color--quaternary  : #2DC17A;
$color--success     : $color--secondary;
$color--error       : #de0000;
$snow-white         : #FFFFFF;
$darth-vader        : #000000;
$white              : $snow-white;
$black              : $darth-vader;
$grey               : #baccd6;


// Color mapping
// -------------
// @example scss - Usage
//   color: color('greys', 'xlight');
//   color: color('primary', 'dark');
//   color: color('secondary');
//   color: color('brands', 'facebook');
//
// return base color as default

$colors-project : (
    greys : (
        xdark       : #1E1E1E,
        dark        : #504b4b,
        base        : #808080,
        light       : #bebebe,
        xlight      : #E8E8E8
        ),
    );


// Transitions
$transition--fast   : 240ms;
$transition--normal : 480ms;
$transition--slow   : 720ms;


// Breakpoints
// -------------
// @example scss - Usage
//   @include mq(md) {...}
//
// create mobile-first media-query ( min-width >= breakpoint )
//
$breakpoints : (
    xxs:  33em, // 528px
    xs:   40em, // 640px
    sm:   48em, // 768px
    mmd:  60em, // 960px
    md:   64.063em, // 1024px
    lg:   80em, // 1280px
    lgx:  87.5em, // 1400px
    xl:   96em  // 1536px
);

// Grid settings
// -------------
// @example scss - Usage
//     @include grid(wrapper-class, $grid-columns, $gutter-width, $container-width, flexbox-enabled?);
//     additionnal params:
//          wrapper-class (ex:'.grid')
//          flexbox-enabled (ex:false)
//
// create responsive grid based on $breakpoints
//
$grid-columns       : 12;
$gutter-width       : 30px;
$gutter-width--small: 20px;
$container-width    : 1565px;

// Components

$zindexes: (
    overlay:    -1,
    overlay-visible:     9,
    nav-main:    10,
    nav-icon:    11
    );


$nav-icon-bar-width  : 3px;
$nav-icon-bar-color  : $darth-vader;

$nav-mobile-width    : 250px !default;

$picture__ratio         : '16/9';
$picture__ratio--apply  : true;

$body-nav-main-animation: ".will-push-left"; //Values: [".will-push-left", ".will-zoom-out"]
