// ==========================================================================
// Associations
// ==========================================================================

.associations {
	margin-top: -.5em;

	figure {
		@extend .col-xxs-4;
		@extend .col-md-3;
		@extend .col-lg-2;
		text-align: center;

		img {
			margin-top: 1.5em;
			max-width: 100%;
		}
	}

	figcaption {
		display: none;
	}
}
