.u-display\@md {
    display: none !important;
    @include mq(md) {
        display: block !important;
    }
}

.u-hidden\@md {
    display: block !important;
    @include mq(md) {
        display: none !important;
    }
}