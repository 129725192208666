.contact {
    @include mq(sm) {
        display: flex;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: auto;
        padding: 2.5rem 4rem;
        color: white;

        @include mq(md) {
            &:hover {
                > div {
                    transform: scale(1.05);
                }
            }
        }

        > div {
            display: flex;
            align-items: flex-start;
            align-items: center;
            transition: transform 240ms ease-in-out;
            backface-visibility: hidden;
            transform: translateZ(0);
        }
    }

    i {
        display: block;
        font-size: 30px;
    }

    [class*='fa-']:not(.fa-angle-right) {
        padding-right: 2rem;

        @include mq(md) {
            padding-right: 3rem;
        }
    }

    .fa-angle-right {
        padding-left: 2rem;
    }
}
