// ==========================================================================
// Typography
// ==========================================================================

.h1, .h2, .h3, .h4, .h5, .h6 {
	font-family: $ff;
	line-height: $lh;
}

.h1 { font-size: $h1; margin: $margin 0 $margin/2 }
.h2 { font-size: $h2; margin: $margin 0 $margin/2 }
.h3 { font-size: $h3; margin: $margin 0 $margin/2 }
.h4 { font-size: $h4; margin: $margin 0 $margin/2 }
.h5 { font-size: $h5; margin: $margin 0 $margin/2 }
.h6 { font-size: $h6; margin: $margin 0 $margin/2 }


.h1 {
	font-size: 2.5em;
	margin-top: 1.250em;
	margin-bottom: .500em;
	line-height: 1;
	font-weight: 300;
}

.h2 {
	font-size: 2.1875em;
	margin-top: 0.857em;
	font-weight: 400;
}

.h3 {
	font-size: 1.1em;
	margin-top: 0.857em;
	font-weight: 400;
	letter-spacing: 1px;
}

.h4 {
	font-size: .75em;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1px;
}

.h5 {
	text-transform: uppercase;
	font-weight: 600;
}

.p {
	margin-bottom: $margin;
	text-align: left;
}

.link {
	color: $color--primary;
	text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

	&:focus {

	}

	&[disabled] {

	}
}

.strong {
	font-weight: 600;
}

.italic {
	font-style: italic;
}

.unordered-list,
.ordered-list {
	padding-left: 1.5em;

	li {
		position: relative;
		&+li {
			margin-bottom: .5em;
		}
	}

}

.content-link {
	color: #006987;
	font-weight: 700;

	&:hover {
		text-decoration: underline;
	}
}

.ordered-list {
	@extend .ordered-list;
	list-style: none;
	padding:0;
	counter-reset: item;
	li {
		margin-bottom: .7em;
	}
	li:before {
		content: counters(item, ".");
		counter-increment: item;
		color: $snow-white;
		background-color: color("brand", "secondary");
		font-size: .9em;
		padding: 1px;
		padding-left: 5px;
		padding-right: 5px;
		margin-right: 10px;
	}

	ul,
	ol {
		margin-top: .7em;
		counter-reset: item;
		li {
			padding-left: 1em;
			margin-bottom: .7em;
			&:before {
				padding-left: 1em;
				color: color("brand", "secondary");
				background: transparent;
			}
		}
	}
}


.unordered-list {
	list-style: none;
	padding-left: 1em;
	li {
		position: relative;
		margin-bottom: .5em;
		&:before {
			@extend .fa;
			position: absolute;
			top: 0; left: -13px;
			content: $fa-var-angle-right;
			color: color("brand", "secondary");
			border: none;
			font-weight: 600;
		}

		ul {
			margin-top: .5em;
			li {
				&:before {
					@extend .fa;
					color: color("brand", "secondary");
					content: $fa-var-caret-right;
					cursor: default;
				}
			}
		}
	}
}

.address {
	font-size: .875em;
	font-style: normal;
	line-height: 1.5;
}

a {
	cursor: pointer !important;
}

.typography {
	h1, .h1 { @extend .h1; }
	h2, .h2 { @extend .h2; }
	h3, .h3 { @extend .h3; }
	h4, .h4 { @extend .h4; }
	h5, .h5 { @extend .h5; }
	h6, .h6 { @extend .h6; }
	p { @extend .p }
	a { @extend .link; }
	strong, b { @extend .strong; }
	em, i { @extend .italic; }
	ul { @extend .unordered-list; }
	ol { @extend .ordered-list; }
    address { @extend .address; }


    .breakline-mobile {
        display: block;
        margin: 5px 0;
        @include mq(sm) {
            display: none;
        }
    }
}
