[class^="container--"] {
	.container {
		@include clearfix;
	}

	section {
		.h2 {
			font-size: 1.2em;
			line-height: 1.4;
			font-weight: 300;

			@include mq(sm) {
				font-size: 1.75em;
			}
		}
	}
}

.container {
	&--bg-small {

		background-size: 100% 305px;

		@include mq(lgx) {
			background-size: 100% 342px;
		}
	}

	&--accueil {
		position: relative;

		section {
			@extend .col-md-9;
			@extend .col-lg-6;
		}

		@include mq(md) {
			.container {
				section {
					width: 60%;
				}
			}
		}

		.cta.featured {

			figure {
				float: left;
				padding-right: 1em;
				max-width: 40%;
			}

			figcaption {
				display: none;
			}

			p {
				font-size: 1em;
			}
		}

		.accueil-slider.slick-slider {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			margin-bottom: 0;
			overflow-y: hidden;

			.slick-list,
			.slick-track {
				height: 100%;
			}
			.slick-slide {
				background-position-x: 65%;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}

		@include mq(lgx) {
			.cta-header {
			}
		}

		@include mq(md) {
			.container {
				section {
					width: 44%;
				}
			}
		}
	}

	&--first {

		.cta__header {

			.cta__title {
				font-size: 1.5em;
			}

		}
	}

	&--team {

		.cta {
			@include mq(md) {
				float: left;
				width: 48.75%;

				& + .cta {
					margin-left: 2.5%;
				}
			}
		}
    }

    &--faq {
        .cta {
            margin-top: 2px;
            margin-bottom: 0;
        }
        padding-bottom: 1rem;

    }

	&--treatments {
        .cta,
        .cta.featured {
            margin-top: 2px;
            padding: 0;

            .cta__title {
                font-size: 1.2em;
                min-height: 45px;
                margin-top: 0;
            }

			.cta__header {
                margin: 0;
                padding: 0;
            }

            .cta__media {
                margin: 0;
            }

            .cta__content {
                padding: 1em;
                @include mq(sm) {
                    padding: 1.5em 1.5em 80px;
                }
            }

			@include mq(sm) {
				.btn--primary {
					position: absolute;
					right: 1em;
					bottom: 1em;
				}
			}
			.btn--primary {
				width: calc( 100% - 2em );
				margin: 0;
            }

			&.featured {

				& > .cta__media {
					margin: -1em -1em -5em;
				}

				@include mq(xs) {
					.btn--primary {
						width: calc( 50% - 1em );
					}
				}
			}
		}
	}

	&--treatment {

		.cta__media {
			display: none;

			@include mq(xs) {
				display: block;
			}
		}
		.tab__head {

            @include mq(md) {
                display: flex;
                align-items: center;
                justify-content: flex-start;
            }

			& > div {
				@include mq(md) {
                    flex-grow: 0;
                    flex-shrink: 0;
                    flex-basis: auto;
                    padding: .5em;

					&:not(:last-child) {
						border-right: 1px solid color(greys,xlight);
					}
                }

                @include mq(lg) {
                    padding: 1em 1.5em;
                }
			}
		}

		figure {
			padding-bottom: 1.5em;

			@include mq(xxs) {
				padding-bottom: 0;
			}
		}
	}

	&--experience {
        .row {
            display: flex;
            flex-wrap: wrap;
        }

		img {
			margin: 1em 0;
        }

        .cta {
            box-shadow: none;
        }

		.cta:nth-child(odd) {
			padding: 1em 4% 1em 1em;
		}

		.cta:nth-child(even) {
			padding: 1em  1em 1em 4%;
		}
	}

	&--first,
	&--patient {
		.cta {
			&__media {
				margin: 0;
				display: none;
			}

			@include mq(xs) {
				&.side {
					.cta__content {
						width: 50%;
						padding-right: 2em;
					}

					.cta__media {
						width: calc( 50% - 2em );
						display: block;
					}
				}
			}
		}
	}

	&--contact {

		.cta__title {
			margin-top: 0;
		}

		.tab__head {
			& > div {
				float:left;
			}
		}

		.cta__content {
			padding-left: .75em;
		}

		.office {
			margin-bottom: 1.5em;

			img {
				float: left;
				margin-right: 1em;
			}

			p {
				margin-left: 72px;
			}
		}

		p {
			margin-bottom: 1.5em;
		}

		.map {
			margin-bottom: 2em;
			height: 23.5em;

			&-pin {
				cursor: pointer;
			}

			&-loaded {
				.map-fake {
					display: none;
				}
			}
		}

		ul {
			list-style-type: none;
			color: $color--primary;
			text-transform: normal;
		}

		.proximity,
		.informations {
			padding-top: 10px;
		}

		.proximity {
			max-height: 700px;
			overflow-y: auto;

			.h5 {
				margin-top: 2em;
				text-transform: none;

				&:first-child {
					margin-top: -0.5em;
				}
			}
		}
	}

	&--professional {

		.container {
			.cta {
				width: 100%;

				@include mq(xxs) {
					width: 49.5%;

					&:first-of-type {
						margin-right: 1%;
					}
				}
			}
		}
	}

	&--fourofour {

		.cta {
			margin-top: 4em;
			.cta__content {
				width: 100%;
				margin-left: 0;
			}

			.cta__header {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
		}

		.error-code {
			margin-bottom: 0;
			font-size: 8em;
			font-weight: 900;
			color: $grey;
			line-height: 1;
		}

		.error-message {
			margin-top: 1.5em;
			margin-bottom: .25em;
			font-size: 1.5em;
			color: $color--primary;
		}

		.btn--primary {
			margin-right: 0;
		}
	}

	&--contact,
	&--experience,
	&--professional {
		.cta {
			@extend .col-xs-6;
		}
	}

	&--faq {
        .cta {
            &__title {
                line-height: 1.5;
                letter-spacing: .5px;
            }
        }
	}
}


.site-container > [class*='container--'] {
    background: linear-gradient(148.07deg, #166A88 0%, #27AB7D 100%) , #FFFFFF;
        background-color: white;
        background-repeat: no-repeat;
        &.container--bg-small {

            background-size: 100% 305px;

            @include mq(lgx) {
                background-size: 100% 342px;
            }
        }

}
