.team-card {
    display: flex;
    height: 100%;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);

    .team-card__media {
        @include keep-ratio("13/8");
        width: 40%;
    }

    .team-card__body {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        width: 60%;
        color: #4c4c4c;
        padding: 1rem;
        text-align: left;

        @include mq(md) {
            padding-left: 3rem;
            padding-right: 3rem;
        }
    }

    .team-card__title {
        font-size: 22px;

        @include mq(lg) {
            font-size: 30px;
        }

    }

    .team-card__subtitle {
        font-size: 16px;

        @include mq(lg) {
            font-size: 18px;
        }
    }
}
