// ========================================================
// Layouts
// ========================================================

@include grid('.container', $grid-columns, $gutter-width, $container-width, false);

body {
	@extend #{$body-nav-main-animation};
	margin: 0;
}

.site-container,
[class^="container--"] {
	background-size: cover;
	background-position: left top;
	background-repeat: no-repeat;

	&:not(.site-container) {
		padding-top: 2em;

		@include mq(sm) {
			padding-top: 4em;
		}
	}

	@include mq(sm) {
		min-height: calc(100vh - 8.45rem);
	}

	section .h2 {
		color: $white;
		margin-top: 0;
		margin-bottom: 1.7em;
	}
}

@import "sections";
