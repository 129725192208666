
.mega-banner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/dist/images/homepage/banner-large.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 500px;
    height: calc(100vh - 95px);

    @media screen and (max-width: 640px) {
        background-position: calc(50% - 150px) 50%;
    }

    .container:nth-child(1) {
        position: absolute;
        top: 0;
    }

    .container:nth-child(2) {
        @include mq(sm) {
            padding-left: $gutter-width--small*2;
            padding-right: $gutter-width--small*2;
        }
    }
}
