// ==========================================================================
// Buttons
// ==========================================================================

$button-border-width	: $border__width !default;
$button-border-color	: $border__color !default;
$button-border-radius	: 3px !default;

$button-margin		: $margin !default;
$button-padding		: 1em !default;


@include btn();


.btn--primary {
	@extend .btn;

	border: 0;

	margin: 0 .4em .4em 0;

	line-height: 1;
	letter-spacing: 1px;
	text-transform: uppercase;
	text-align: center;
	font-weight: 700;

	color: $white;
	background-color: #2DC17A;

	transition: background-color $transition--fast ease-in-out;

	&:hover {
		background-color:  #166A88;
	}
}

.btn--primary--invert {
    border: 1px solid #166A88;
    background-color:  #166A88;

    span {
        display: inline-block;
        transform: translateX(10px);
        transition: transform 240ms ease-in-out;
    }

    .fa-smile {
        opacity: 0;
        transition: opacity 150ms ease-in-out;
    }

    &:hover {
        color: #166A88;
        border-color: #166A88;
        background-color:  #ffffff;

        span {
            transform: translateX(0);
        }

        .fa-smile {
            opacity: 1;
            transition: opacity 240ms ease-in-out 150ms;
        }
	}
}

.btn--print,
.btn--pdf {
	@extend .fa;
	padding: .6875em;

	&:before {
		font-size: 1.5em;
		font-weight: 400;
	}
}

.btn--wide {
	width: 100%;
}

.btn--print {
	@extend .fa-print;
}

.btn--pdf {
	@extend .fa-file-pdf-o;
}

.btn--icon {
	position: relative;
	width: 2.75em;
	height: 2.75em;
	background-color: color(brand, primary);
	border-radius: 100%;

	i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 1.5em;
		color: $snow-white;
	}
}

.btn--contact {
	display: none;

	@include mq(md) {
		display: inline-block;
		position: absolute;
		top: -4em;
		right: 15px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}

.btn__group {
    margin-top: 1rem;
    text-align: right;
}




.btn-v2 {
    display: inline-block;
    text-align: center;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-top: .5rem;
    padding-bottom: .7rem;
    border-radius: $button-border-radius;
    transition: all 240ms ease-in-out;

    i {
        position: relative;
        top: 4px;
        padding-left: .2rem;
    }
}

.btn-v2--primary {
    border: 1px solid transparent;
    color: color(brand, tertiary);
    background-color: white;

    @include mq(md) {
        &:hover {
            border-color: white;
            color: white;
            background-color: transparent;
        }
    }
}

.btn-v2--secondary {
    border: 1px solid transparent;
    color: white;
    background-color: color(brand, tertiary);

    @include mq(md) {
        &:hover {
            border-color: color(brand, tertiary);
            color: color(brand, tertiary);
            background-color: transparent;
        }
    }
}

.btn-v2--follow,
.btn-v2--appointement {
    color: white;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.2;
    padding: 1rem;


    > div {
        display: flex;
        align-items: center;
    }

    i {
        display: block;
        position: static;
        padding-right: 1rem;
        padding-left: 1rem;
    }
}

@media screen and (min-width: 768px) {
    .btn-v2--follow,
    .btn-v2--appointement {
        padding: .5rem;

        i {
            padding-left: .5rem;
            padding-right: 1rem;
        }
    }
}

@media screen and (min-width: 1024px) {
    .btn-v2--follow,
    .btn-v2--appointement {
        padding: 1rem;
    }
}

.btn-v2--follow {
    background-color: color(brand, tertiary);
}

.btn-v2--appointement {
    background-color: color(brand, quaternary);
}

.c-link {
    --link-color: white;
    --link-font-size: inherit;
    --link-font-weight: 700;
    --link-hover-color: #{$grey};

    color: var(--link-color);
    font-size: var(--link-font-size);
    font-weight: var(--link-font-weight);

    @media (any-hover: hover) {
        &:hover {
            color: var(--link-hover-color);
        }
    }
}
