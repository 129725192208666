
$cta-list-padding : $padding;
$cta-padding : $padding;
$cta-sm-padding : $padding;

@include card('.cta',false, $cta-padding);

@include mq(sm) {
	@include card('.cta',false, $cta-sm-padding);
}

.cta {
	position: relative;
	margin-bottom: 3em;
    background-color: $white;
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.1);

	.cta__header {
		margin-bottom: 1.5em;
	}

	.cta__title {
		@extend .h3;
		line-height: 1;
		margin: 1em 0 0;
		color: color(brand, primary);
	}

	.cta__subtitle {
		@extend .h4;
		margin: 1em 0 0;
	}

	.cta__content {
		padding-top: 1em;
		line-height: 1.5;

        p a {
            color: #006987;
            font-weight: 700;

            &:hover {
                text-decoration: underline;
            }
        }
	}

	.cta__footer  {
		margin-bottom: 1em;
	}

	& > .cta__media {
		margin: -1em -1em;

		img {
			width: 100%;
            display: block;
		}

		figcaption {
			display: none;
        }

		&--has-grayscale-hover {
			position: relative;
		}

		.cta__media-grayscale {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			bottom: 0;
			right: 0;
			display: block;
			transition: $transition--fast ease;
			opacity: 0;
			will-change: opacity;
		}
        @include mq(lg) {
            &--has-grayscale-hover {
				.cta__media-grayscale {
                    opacity: 1;
				}
				&:hover {
					.cta__media-grayscale {
					    opacity: 0;
					}
				}
            }
        }
    }


	img {
		max-width: 100%;
	}

	p {
		@extend .p;
		font-size: .875em;
	}

	ul {
		li {
			font-size: .875em;
	
			&::before {
				top: 3px;
			}
		}
	}

	.btn--primary {
		margin-top: 1em;
	}

	&.side,
	&.featured {
		padding: 1em;

		.cta__header {
			margin-top: 0;
		}

		.cta__title {
			margin: 0;
			font-size: 2.1875em;
		}

		.cta__content {
			margin: 0;
		}

		@include mq(xs) {
			.cta__media,
			.cta__content  {
				float: left;
				text-align: left;
			}

			.cta__header {
				padding: 0;
				margin: 0 0 1.5em;
			}

			.cta__content {
				margin: 0;
				margin-left: $cta-list-padding * 2;
			}
		}
	}

	&.side {
		@include mq(xs) {
			.cta__media {
				width: 25%;
			}

			.cta__content {
				width: 75%;
			}
		}
	}

	&.featured {
		@include mq(xs) {
			.cta__media,
			.cta__content {
				width: 50%;
			}
		}
    }
    @include mq(lg) {
        .cta__media--has-overlay-hover {
            &:hover {
                &::before {
                    opacity: 0;
                }
            }
        }
    }
}

.cta--shadow {
    transition: transform $transition--fast ease-in-out, box-shadow $transition--fast ease-in-out;
    will-change: box-shadow;
    @include mq(lg) {
        &:hover {
            transform: translate3d(0, -5px , 0);
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .15);
            .btn--primary {
                background-color: $color--primary;
            }
        }
    }
}

.banner__ctas {
	display: none;
	
	@include mq(md) {
		z-index: 1;
		display: flex;
		align-items: center;
		flex-direction: column;
		gap: .5em;
		position: absolute;
		top: 0;
		right: 15px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
}
