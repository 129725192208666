.social-icons {
	a {
		&:before {
			@extend .fa;
			line-height: inherit;
			font-size: 1em;
	    }
		@each $social, $icon in $socials {
			&[href*="#{$social}"] {
				background-color: map-get($icon, color);
				&:before {
					content: map-get($icon, char);
				}
			}
		}
	}
}