// ==========================================================================
// Navigation
// ==========================================================================
$nav-main-height 		: 6.25rem;
$nav-sm-height 			: 7.5rem;


@include nav-main('.nav-main', $nav-main-height, true);


.nav-main > .container {
	position: relative;
}

.nav-main .nav-main__panel {
	overflow: visible;
}

.nav-main .nav-main__panel .nav-main__primary {

	@include mq(md) {
		float: right;
	}

	> li {
		position: relative;
        &.fb a {
            @extend .fa;
            @extend .fa-facebook;
            font-size: 1.2em;
            position: relative;
            top: 4px;
            color: #504b4b;
            @include mq(md) {
                &::before {
                    color: color(brand, primary);
                }
            }
        }


		@include mq(md) {
			float: left;


			&:not(.fb) {
				&:before {
					content:"";
					display: block;
					width: 100%;
					height: 100%;
					position: absolute;
					z-index:0;
					left: 0;
					top: 0;
					background-color: $white;
					transition: background-color $transition--fast ease-in-out,
					top $transition--fast ease-in-out;
					top: -#{$nav-main-height};

				}
				&:hover {
					&:before {
						background-color: color(brand, primary);
						top:0;
					}
					a,
					span {
						color: $white;
					}
				}
			}
		}

		&.fb {
			padding-top: 1em;

			@include mq(md) {
				padding:0;
			}

			a {
				text-align: center;
				border: 0;
				font-size: 1.5em;

				@include mq(md) {
					text-align: left;
					font-size: 1em;
				}
			}
		}
		> a,
		> span {
			position: relative;
			display: inline-block;
			width: 100%;
			padding: .5em 1em;
			color: color(greys, dark);
			letter-spacing: .5px;
			border-bottom: 1px solid color(brand, primary);

			@include mq(md) {
				line-height: 1.5;
				padding: calc(#{$nav-main-height} - 2.8125rem) #{'clamp(0.375rem, 1.875vw + -0.75rem, 0.75rem)'} 1.5em;
				font-size: #{'clamp(0.75em, 1.25vw, 1em)'};
				z-index:1;
				border:0;
				transition: color $transition--fast ease-in-out #{$transition--fast - 150};
			}

			@include mq(lg) {
				padding-left: #{'clamp(0.875em, 1.667vw + -0.458em, 1em)'};
				padding-right: #{'clamp(0.875em, 1.667vw + -0.458em, 1em)'};
				font-size: 1em;
			}
		}


	}
}


//sub menu
.nav-main .nav-main__panel .nav-main__primary {

	> li {
		position: relative;

		@include mq(md) {
			&:hover  {
				ul {
					position: absolute;
					opacity: 1;
					visibility: visible;

					&:hover:before {
						width: 100%;
					}
				}
				li:not(.fb) a {
					color: $fc;
				}
			}
		}

		ul {


			@include mq(md) {
				position: absolute;
				opacity: 0;
				visibility: hidden;
				transition: all $transition--fast ease-in-out;
			}

			background-color: $white;

			li:not(.fb) {
				position: relative;
				text-align: left;
				border-bottom: 1px solid color(brand, primary);

				@include mq(md) {
					border:0;

					&:hover{
						&:before {
							width: 100%;
						}
						a {
							color: $white;
						}
					}
				}
				&:before {
					content:"";
					position: absolute;
					left:0;
					top:0;
					display: block;
					width: 5px;
					height: 100%;
					background-color: color(brand, primary);
					transition: width $transition--fast ease-in-out;

					@include mq(md) {
						width: 3px;
					}
				}

				a {
					position: relative;
					display: inline-block;
					width: 100%;
					padding: .5em 1em;

					@include mq(md) {
						line-height: 1;
						padding: .8em 1em;
						transition: color $transition--fast ease-in-out #{$transition--fast - 200};
					}
				}
			}
		}
	}
}

.link-hightlight,
.nav-main .nav-main__panel .nav-main__primary > li > a.link-hightlight {
	color: white;
	background-color: color(brand, primary);
	font-weight: 700;
	text-transform: uppercase;
	padding-top: 1em;
	padding-bottom: 1em;
	
	@include mq(md) {
		display: none;
	}
}
