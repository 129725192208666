[gr-vgrid~='mt-1'] {
    margin-top: 1rem;
}

[gr-vgrid~='mt-2'] {
    margin-top: 1rem;

    @include mq(md) {
        margin-top: 2rem;
    }
}

[gr-vgrid~='mt-3'] {
    margin-top: 1.5rem;

    @include mq(md) {
        margin-top: 3rem;
    }
}

[gr-vgrid~='mt-4'] {
    margin-top: 2rem;

    @include mq(md) {
        margin-top: 4rem
    }
}

[gr-vgrid~='mb-1'] {
    margin-bottom: 1rem;
}

[gr-vgrid~='mb-2'] {
    margin-bottom: 1rem;

    @include mq(md) {
        margin-bottom: 2rem;
    }
}

[gr-vgrid~='mb-3'] {
    margin-bottom: 1.5rem;

    @include mq(md) {
        margin-bottom: 3rem;
    }
}

[gr-vgrid~='mb-4'] {
    margin-bottom: 2rem;

    @include mq(md) {
        margin-bottom: 4rem
    }
}



[gr-vgrid~='pt-1'] {
    padding-top: 1rem;
}

[gr-vgrid~='pt-2'] {
    padding-top: 1rem;

    @include mq(md) {
        padding-top: 2rem;
    }
}

[gr-vgrid~='pt-3'] {
    padding-top: 1.5rem;

    @include mq(md) {
        padding-top: 3rem;
    }
}

[gr-vgrid~='pt-4'] {
    padding-top: 2rem;

    @include mq(md) {
        padding-top: 4rem
    }
}

[gr-vgrid~='pb-1'] {
    padding-bottom: 1rem;
}

[gr-vgrid~='pb-2'] {
    padding-bottom: 1rem;

    @include mq(md) {
        padding-bottom: 2rem;
    }
}

[gr-vgrid~='pb-3'] {
    padding-bottom: 1.5rem;

    @include mq(md) {
        padding-bottom: 3rem;
    }
}

[gr-vgrid~='pb-4'] {
    padding-bottom: 2rem;

    @include mq(md) {
        padding-bottom: 4rem
    }
}



@mixin keep-ratio($ratio: '1/1') {
    $ratio1: str-slice($ratio, 1, (str-index($ratio, '/') - 1));
    $ratio2: str-slice($ratio, (str-index($ratio, '/') + 1));

    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: cover;

    &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: calc(#{$ratio2} * 100 / #{$ratio1} * 1%);
    }

    & > * {
        //display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin change-ratio($ratio: '1/1', $breakpoint: '') {
    $ratio1: str-slice($ratio, 1, (str-index($ratio, '/') - 1));
    $ratio2: str-slice($ratio, (str-index($ratio, '/') + 1));
        @include mq($breakpoint) {
            &::before {
                padding-top: calc(#{$ratio2} * 100 / #{$ratio1} * 1%);
            }
        }
}

